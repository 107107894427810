/* stylelint-disable no-descending-specificity */
@import 'https://fonts.googleapis.com/css2?family=Aoboshi+One&family=RocknRoll+One&display=swap';

* {
  box-sizing: border-box;
}

:root {
  --black: #0b141c;
  --white: #f2efe2;
  --primary-color: var(--black);
  --primary-color-subdued: #555348;
  --secondary-color: #dad6c5;
  --bg-color: var(--white);
  --font-size: 16px;
  --indigo: #395298;
  --lightblue: #51b2c9;
  --green: #53822b;
  --teal: #6dcf97;
  --blue: #76b2b4;
  --lightgreen: #8fb505;
  --purple: #a068e8;
  --brown: #c26655;
  --tea: #c9c31e;
  --beige: #d6a672;
  --red: #d94550;
  --red-subdued: #efd1c8;
  --yellow: #e8a21c;
  --scarlet: #eb5c4b;
  --pink: #ed9489;
  --palepink: #f1b8b5;
  --lightbeige: #f7e3c1;
  --orange: #ffb554;
  --border: 4px solid var(--primary-color);
  --mode-caption-color: #7a5154;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }

  :root {
    --primary-color: var(--white);
    --primary-color-subdued: #dad6c5;
    --secondary-color: #2e363e;
    --bg-color: #141d26;
    --red-subdued: #4e2629;
    --mode-caption-color: #b17b7f;
  }

  .gamewrapper {
    /* --primary-color: var(--black);
    --primary-color-subdued: #555348;
    --secondary-color: #dad6c5;
    --bg-color: var(--white);
    --border: 4px solid var(--primary-color);

    color: var(--primary-color); */
  }
}

::selection {
  color: var(--black);
  background-color: var(--red);
}

html,
body {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  background: var(--bg-color);
}

body {
  position: relative;
  font-family: 'Aoboshi One', 'RocknRoll One', sans-serif;
  font-feature-settings: 'palt';
  color: var(--primary-color);
  transition: background-color 0.2s;
}

a {
  color: var(--primary-color);
  text-decoration: none;
  opacity: 0.5;
}

a:hover {
  opacity: 1;
}

em {
  font-style: normal;
  color: var(--red);
  background: transparent;
}

/* a,
button {
  transition: transform 0.2s;
}

a:hover,
button:hover {
  transform: scale(0.98);
}

a:active,
button:active {
  transform: scale(0.95);
} */

.button-link {
  display: inline-flex;
  align-items: center;
  padding: 0;
  font-weight: inherit;
  color: var(--primary-color);
  cursor: pointer;
  background: transparent;
  border: 0;
  opacity: 0.7;
  appearance: none;
}

.button-link:hover {
  opacity: 0.85;
}

.button-hinomaru {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 101px;
  height: 101px;
  padding: 5px;
  margin: 10px auto;
  font-size: 27px;
  font-weight: 900;
  line-height: 1.03;
  color: var(--white);
  cursor: pointer;
  background: var(--red);
  border: 0;
  border-radius: 50%;
  appearance: none;
}

.button-hinomaru:hover {
  background: #be3943;
}

.bordercomp {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 4px 15px;
  margin: 3px;
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  color: var(--primary-color);
  vertical-align: middle;
  background: transparent;
  border: var(--border);
  border-radius: 23px;
  appearance: none;
}

.bordercomp[disabled] {
  pointer-events: none;
  opacity: 0.3;
}

.bordercomp.simple {
  border: 0;
}

.bordercomp[data-active='true'] {
  color: var(--bg-color);
  background: var(--primary-color);
  animation: 0.4s ease 0s 1 normal clicked;
}

.bordercomp.mode-more-button {
  color: var(--primary-color-subdued);
  background: var(--secondary-color);
  border-color: var(--secondary-color);
}

.bordercomp.mode-more-button[data-active='true'] {
  color: var(--red);
  background: var(--red-subdued);
  border-color: var(--red-subdued);
}

.mode-with-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mode-with-icon svg,
.modes-more .mode-with-icon svg {
  width: 26px;
  height: 26px;
  margin: 0 -10px;
  transition: 0.2s;
}

.mode-with-icon span,
.modes-more .mode-with-icon span {
  max-width: 0;
  overflow: hidden;
  transition: 0.2s;
}

.mode-more-button svg {
  transform: rotate(90deg);
}

h4 .mode-with-icon svg {
  width: 32px;
  height: 32px;
  margin-right: 2px;
  margin-left: -2px;
}

h4 .mode-with-icon span {
  max-width: 12em;
}

[data-active='true'] .mode-with-icon svg,
.modes-more [data-active='true'] .mode-with-icon svg {
  margin-right: 2px;
  margin-left: -2px;
}

[data-active='true'] .mode-with-icon span,
.modes-more [data-active='true'] .mode-with-icon span {
  max-width: 12em;
}

.modes-more,
.modes-more-wrapper {
  display: contents;
  transition: 0.2s;
}

.mode-more-button {
  display: none;
}

.mode-divider {
  display: block;
  height: 35px;
  margin: 0 14px;
  border-left: 2px solid var(--secondary-color);
}

@media (min-width: 768px) {
  .mode-divider {
    display: none;
  }

  .mode-with-icon svg {
    margin-right: 2px;
    margin-left: -2px;
  }

  .mode-with-icon span {
    max-width: 12em;
  }

  .mode-more-button {
    display: flex;
  }

  .modes-more-wrapper {
    position: relative;
    display: block;
    width: 100%;
  }

  .modes-more-wrapper::after,
  .modes-more-wrapper::before {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 10px;
    height: 60px;
    pointer-events: none;
    content: '';
    background-image:
      linear-gradient(
        90deg,
        transparent 0%,
        var(--bg-color) 100%
      );
  }

  .modes-more-wrapper::before {
    left: 0;
    background-image:
      linear-gradient(
        -90deg,
        transparent 0%,
        var(--bg-color) 100%
      );
  }

  .modes-more {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 100%;
    max-height: 0;
    padding: 0 10px;
    overflow-x: auto;
    scrollbar-width: none;
  }

  .modes-more[data-open='true'] {
    max-width: 100%;
    max-height: 3em;
  }

  .modes-more::-webkit-scrollbar {
    display: none;
  }

  .mode-more-button svg {
    transform: rotate(0deg);
  }
}

a.bordercomp,
button.bordercomp,
input[type='submit'].bordercomp {
  white-space: nowrap;
  cursor: pointer;
  opacity: 1;
}

.message .bordercomp {
  flex-direction: column;
  align-items: end;
  max-width: 130px;
  padding: 11px 16px;
  font-size: 15px;
  line-height: 1.3;
  text-align: right;
  background-color: var(--white);
  border: 0;
}

.message[data-type='hint'] {
  animation-delay: 0.25s;
  animation-fill-mode: both;
}

.message[data-type='answer'] .bordercomp {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 6px 15px 5px;
  font-size: 17px;
  line-height: 1.1;
  background: transparent;
  border: var(--border);
}

.message[data-type='score'] .bordercomp {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: -8px;
  color: var(--red);
  background: transparent;
}

.message-notice {
  display: block;
  padding: 3px 12px;
  margin-top: 2px;
  margin-right: -10px;
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-color);
  text-align: right;
  white-space: nowrap;
  border-radius: 15px;
  animation: 0.4s ease 0.8s 1 normal blink;
}

.message-notice + .message-notice {
  margin-top: 3px;
}

.icon-circle {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  border: 3px solid;
  border-radius: 20px;
}

@keyframes clicked {
  0% {
    transform: scale(0.8);
  }

  60% {
    opacity: 1;
    transform: scale(1.05);
  }

  80% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes dynamicclicked {
  0% {
    transform: scale(0);
  }

  60% {
    transform: scale(1.06);
  }

  80% {
    transform: scale(0.87);
  }

  100% {
    transform: scale(1);
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transform: translateX(-15px);
}

.loading::before {
  display: block;
  width: 12px;
  height: 12px;
  content: '';
  background: var(--red);
  border-radius: 10px;
  transform: rotate(0deg);
  transform-origin: 15px;
  animation: 1s ease 0s infinite normal prizm-loading;
}

@keyframes prizm-loading {
  0% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(450deg);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

input:focus {
  outline: none;
}

.matched .questioner,
.matched .answerinput,
.matched .message[data-matched='false'],
.matched .chat::before,
.matched footer {
  visibility: hidden;
}

.matched .chat::before {
  opacity: 0;
}

body.matched {
  overflow: hidden;
}

.matchedtext {
  display: none;
}

.matched .matchedtext {
  position: fixed;
  top: 20vh;
  left: 0;
  z-index: 30;
  display: block;
  width: 100vw;
  font-size: 58px;
  font-weight: 900;
  color: var(--black);
  text-align: center;
  letter-spacing: -6px;
  content: 'あたり!';
}

.matched .message[data-matched='true'] {
  position: absolute;
  top: 55%;
  transform: scale(1.6);
}

.message[data-matched='true']::before {
  position: absolute;
  top: -50px;
  left: -20px;
  z-index: -1;
  width: 160px;
  height: 160px;
  pointer-events: none;
  content: '';
  background: var(--bg-color);
  border-radius: 50%;
  opacity: 0;
  transform: scale(0);
}

.chat[data-active='false'] .message[data-disabled='true'] {
  opacity: 0.3;
}

.matched .message[data-matched='true']::before {
  opacity: 1;
  animation: 1s ease 0s 1 both matched-bg;
}

@keyframes matched-bg {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  100% {
    transform: scale(8);
  }
}

.matched .gamewrapper {
  height: 100vh !important;
}

@keyframes message-added {
  0% {
    height: 0;
    transform: scale(0);
  }

  60% {
    transform: scale(1.03);
  }

  80% {
    transform: scale(0.95);
  }

  100% {
    height: auto;
    transform: scale(1);
  }
}

@keyframes message-added-matched {
  0% {
    transform: scale(0.3);
  }

  60% {
    transform: scale(2);
  }

  80% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1.6);
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  49% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes redblink {
  0% {
    color: var(--primary-color);
    border-color: var(--primary-color);
  }

  49% {
    color: var(--red);
    border-color: var(--red);
  }

  50% {
    color: var(--primary-color);
    border-color: var(--primary-color);
  }

  99% {
    color: var(--red);
    border-color: var(--red);
  }

  100% {
    color: var(--primary-color);
    border-color: var(--primary-color);
  }
}

div[data-innertext] > div > span > span > span::before {
  content: attr(data-innertext);
}

.bigquestion-layers {
  position: relative;
}

body.ready .bigquestion-layers {
  animation: 1.5s ease 0s 3 normal zoom;
}

@keyframes zoom {
  0% {
    transform: scale(2);
  }

  100% {
    transform: scale(1.5);
  }
}

.bigquestion-animation {
  animation: 1.5s linear 0s infinite normal rotate-horizontal;
}

@keyframes rotate-horizontal {
  0% {
    transform: rotateX(0deg) rotateY(-82deg);
  }

  10% {
    transform: rotateX(0deg) rotateY(6deg);
  }

  15% {
    transform: rotateX(0deg) rotateY(1deg);
  }

  50% {
    transform: rotateX(0deg) rotateY(-1deg);
  }

  90% {
    transform: rotateX(0deg) rotateY(1deg);
  }

  95% {
    transform: rotateX(0deg) rotateY(-6deg);
  }

  100% {
    transform: rotateX(0deg) rotateY(82deg);
  }
}

.prizm-card {
  width: 340px;
  max-width: 95%;
  max-width: calc(100vw - 60px);
  padding: 15px 20px;
  border: 4px solid var(--primary-color);
  border-radius: 20px;
  transition: 0.2s;
}

.colorselector {
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  vertical-align: middle;
  cursor: pointer;
  border: 0;
  border-radius: 20px;
  appearance: none;
}

body.ready .chat,
body.ready .answerinput,
body.ready .modedisp,
body.ready .questionlist {
  display: none;
}

body.ready .gamegrid {
  grid-template: 1fr / 1fr;
  grid-template-areas: 'questioner';
}

.gamereadywrapper,
.gamecancelonready {
  display: none;
}

body.ready .gamereadywrapper,
body.ready .gamecancelonready {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

body.ready .bigquestioncircle-red {
  background: transparent;
}
